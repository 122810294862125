.carousel__content {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  margin-bottom: 10px;
}

.carousel__item {
  flex: 0 0 100%;
  width: 100%;
}

.carousel__item > p {
  text-align: center;
  margin-bottom: 0;
}

.carousel__nav__list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.carousel__nav__item {
  margin: 0 5px;
}

.carousel__nav__item__link {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #D9D9D9;
  border-radius: 50%;
  font-size: 0;
}

.carousel__nav__item__link--selected {
  background-color: #2C5378;
}
